import React from "react";
import LinkExternal from "../Links/LinkExternal";
import { StaticQuery, graphql } from "gatsby";
import MenuItemsPrimary from "./MenuItemsPrimary";
import MenuItemsSecondary from "./MenuItemsSecondary";

const SiteMenu = () => (
  <StaticQuery
    query={MENU_QUERY}
    render={data => {
      return (
        <div className="nav__menu g__c12">
          <nav
            className="nav__main"
            role="navigation"
            aria-label="Main menu items"
          >
            <MenuItemsPrimary
              filter={data.wp.menuItems.nodes}
              maxElements="1"
            />
          </nav>
          <nav
            className="nav__secondary"
            role="navigation"
            aria-label="Secondary menu items"
          >
            <MenuItemsSecondary
              filter={data.wp.menuItems.nodes}
              offSetStartItems="1"
            />
          </nav>
          <div className="nav__contact">
            <ul className="nav__contact--list">
              <li className="nav__contact--list-item p__bottom20">Aarhus</li>
              <li className="nav__contact--list-item">
                <LinkExternal
                  href="mailto:niels@djbooking.dk"
                  text="niels@djbooking.dk"
                />
              </li>
              <li className="nav__contact--list-item">
                <LinkExternal href="tel:+4570217025" text="+45 7021 7025" />
              </li>
              <li className="nav__contact--list-item">
                <LinkExternal
                  href="https://goo.gl/maps/CMdjtLj42UU2"
                  text="Solhøjvej 24"
                />
              </li>
            </ul>
          </div>
        </div>
      );
    }}
  />
);

export default SiteMenu;

const MENU_QUERY = graphql`
  fragment MenuFields on WP_MenuItem {
    id
    label
    url
    connectedObject {
      __typename
    }
  }
  query GET_MENU_ITEMS {
    wp {
      menuItems(where: { location: MAIN_NAV }) {
        nodes {
          ...MenuFields
          childItems {
            nodes {
              ...MenuFields
              childItems {
                nodes {
                  ...MenuFields
                  childItems {
                    nodes {
                      ...MenuFields
                      childItems {
                        nodes {
                          ...MenuFields
                          childItems {
                            nodes {
                              ...MenuFields
                              childItems {
                                nodes {
                                  ...MenuFields
                                  childItems {
                                    nodes {
                                      ...MenuFields
                                      childItems {
                                        nodes {
                                          ...MenuFields
                                          childItems {
                                            nodes {
                                              ...MenuFields
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
