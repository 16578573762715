import React from 'react'
import { Link } from 'gatsby'
import { createLocalLink } from '../../utils/index'

const LinkInternal = props => (
  <Link to={createLocalLink(props.href)} className="link__underline link__underline-green link__large">
    <span className="link__label">
      {props.text}
    </span>
    {props.children}
  </Link>
)

export default LinkInternal
