import React from "react";

function LinkExternal({ href, target, text }) {
  return (
    <a
      href={href}
      target={target}
      className="link__underline link__underline-green link__large"
    >
      <span className="link__label">{text}</span>
    </a>
  );
}

export default LinkExternal;
