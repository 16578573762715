import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

const HelmetData = ({ seo }) => {
  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="keywords" content={seo.focuskw} />
      <meta name="description" content={seo.metaDesc} />
      <meta name="robots" content="index,follow" />
      <script
        type="text/javascript"
        charset="utf-8"
        src="//sleeknotecustomerscripts.sleeknote.com/19480.js"
      />
      <meta name="msvalidate.01" content="AEBB5D21F83E4F6EA11901949649D5DA" />
      <meta
        name="google-site-verification"
        content="g8Vp5oz_MjIQ9itQGs_x94wQFBMBoHTJIzdC_g0HSGA"
      />
    </Helmet>
  );
};

export default HelmetData;

export const query = graphql`
  fragment YoastFields on WP_SEO {
    title
    metaDesc
    focuskw
    metaKeywords
    metaRobotsNoindex
    metaRobotsNofollow
  }
`;
