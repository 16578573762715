import React, { Fragment } from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const Title = styled.p`
  ${tw`text-2xl font-bold`}
`;

const FooterTitle = props => {
  return <Title>{props.titleText}</Title>;
};

export default FooterTitle;
