import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const ContainerStyle = styled.div`
  ${tw`mx-auto px-4`}
  height: 100%;
`

const Container = ({ children }) => (
  <ContainerStyle className="container">{children}</ContainerStyle>
);

export default Container;
