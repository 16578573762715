import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import MenuItems from "./MenuItems";
import DesktopItems from "./DesktopItems";
import Media from "react-media";
import Search from "../Search";
import Logo from "./Logo";
import Container from "../Container"

const searchIndices = [
  { name: `Artister`, title: `Artister`, hitComp: `ArtistHit` }
];

export default function MainMenu() {
  const [active, setActive] = useState(false);

  function toggle() {
    setActive(!active);
  }

  return (
      <header className={"nav__container nav__background"}>
        <Container>
          <div className="nav__header">
            <Link to="/">
              <Logo />
            </Link>
            <Media query={{ minWidth: 900 }}>
              <DesktopItems />
            </Media>
            <Media query={{ maxWidth: 900 }}>
              <div className="flex">
                <Search collapse indices={searchIndices} />
                <div className="flex flex__align-middle">
                  <div className="nav__mobile--menu" onClick={toggle}>
                    <svg
                      className={"ham ham6 " + (active ? "active" : "")}
                      viewBox="0 0 100 100"
                      width="80"
                    >
                      <path
                        className="line top"
                        d="m 30,33 h 40 c 13.100415,0 14.380204,31.80258 6.899646,33.421777 -24.612039,5.327373 9.016154,-52.337577 -12.75751,-30.563913 l -28.284272,28.284272"
                      />
                      <path
                        className="line middle"
                        d="m 70,50 c 0,0 -32.213436,0 -40,0 -7.786564,0 -6.428571,-4.640244 -6.428571,-8.571429 0,-5.895471 6.073743,-11.783399 12.286435,-5.570707 6.212692,6.212692 28.284272,28.284272 28.284272,28.284272"
                      />
                      <path
                        className="line bottom"
                        d="m 69.575405,67.073826 h -40 c -13.100415,0 -14.380204,-31.80258 -6.899646,-33.421777 24.612039,-5.327373 -9.016154,52.337577 12.75751,30.563913 l 28.284272,-28.284272"
                      />
                    </svg>
                  </div>
                </div>
                <div className={ "nav__mobile nav__header " + (active ? "js--active" : "") }>
                  <Container>
                    <div className="nav__mobile--overlay"></div>
                    <div className="nav__mobile--container">
                      <div className="g__row">
                        <MenuItems />
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
            </Media>
          </div>
        </Container>
      </header>
  );
}
