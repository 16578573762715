import React from 'react'
import Link from 'gatsby-link'
import { createLocalLink } from '../../utils'

const MenuItemsSecondary = props => {
  return (
    <ul className="nav__secondary--list">
      {props.filter.map((menuItem, index) => {
        const link = createLocalLink(menuItem.url)
        let normalizeIndexVal = index + 1

        if (normalizeIndexVal > props.offSetStartItems) {
          return (
            <li key={menuItem.label} className="nav__secondary--list-item">
              <Link to={link}>{menuItem.label}</Link>
            </li>
          )
        }
      })}
      <li className="nav__secondary--list-item">
        <Link to="/kontakt/">Kontakt</Link>
      </li>
    </ul>
  )
}

export default MenuItemsSecondary
