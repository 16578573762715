import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#FFFFFF"
    },
    secondary: {
      main: "#FFE52B"
    }
  },
  typography: {
    useNextVariants: true,
    fontSize: "1.6rem",
    htmlFontSize: 10
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "none",
        boxShadow: "none !important"
      }
    },
    MuiExpansionPanel: {
      root: {
        "&::before": {
          backgroundColor: "#dddddd",
          opacity: "0 !important"
        }
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: "1.6rem"
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: "0px",
        marginBottom: ".5rem"
      },
      expandIcon: {
        color: "rgba(255, 255, 255, 0.7)",
        right: "0px"
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: "0px"
      }
    }
  }
});
