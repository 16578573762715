import React from "react";
import { Link } from "gatsby";
import { createLocalLink } from "../../utils";

const MenuItemsPrimary = (props) => {
  return (
    <ul className="nav__category-wrapper">
      {props.filter.map((menuItem, index) => {
        const link = createLocalLink(menuItem.url);
        let normalizeIndexVal = index + 1;

        if (normalizeIndexVal <= props.maxElements) {
          let nestedMenuItems = [];
          if (menuItem.childItems.nodes.length > 0) {
            menuItem.childItems.nodes.map((nestedItem) => {
              nestedMenuItems.push(nestedItem);
            });

            return nestedMenuItems.map((item) => {
              const nestedLink = createLocalLink(item.url);

              return (
                <li key={item.id} className="nav__category-item">
                  <span className="nav__category-item-link">
                    <Link to={nestedLink}>{item.label}</Link>
                  </span>
                </li>
              );
            });
          } else {
            return (
              <li key={menuItem.id} className="nav__category-item">
                <span className="nav__category-item-link">
                  <Link to={link}>{menuItem.label}</Link>
                </span>
              </li>
            );
          }
        }
      })}
    </ul>
  );
};

export default MenuItemsPrimary;
