import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
  }
}

export default Layout;
