import React from "react";
import Link from "gatsby-link";
import { StaticQuery, graphql } from "gatsby";
import { createLocalLink } from "../../utils";
import Search from "../Search";
import Button from "../Button";

const searchIndices = [
  { name: `Artister`, title: `Artister`, hitComp: `ArtistHit` },
];

const RenderMenuItems = (props) => {
  const nestedMenuItems = [];

  return (
    <ul className="nav__category-wrapper">
      {props.data.map((menuItem, index) => {
        const link = createLocalLink(menuItem.url);

        if (menuItem.childItems.nodes.length !== 0) {
          return (
            <div key={index} className="nav__dropdown">
              <button className="nav__dropdown-btn f__bold">
                {menuItem.label}
              </button>
              <div className="nav__dropdown-content">
                {menuItem.childItems.nodes.map((nestedItem, nestedIndex) => {
                  const nestedLink = createLocalLink(nestedItem.url);
                  nestedMenuItems.push(nestedItem.label);

                  return (
                    <Link key={nestedIndex} to={nestedLink}>
                      {nestedItem.label}
                    </Link>
                  );
                })}
              </div>
            </div>
          );
        }

        if (!nestedMenuItems.includes(menuItem.label)) {
          return (
            <li key={menuItem.id} className="nav__category-item">
              <span className="nav__category-item-link">
                <Link to={link}>{menuItem.label}</Link>
              </span>
            </li>
          );
        }
      })}

      <Button
        primary={true}
        to="/kontakt/"
        style={{ marginLeft: "1rem", marginRight: "1rem" }}
      >
        Kontakt
      </Button>
    </ul>
  );
};

const SiteMenu = () => (
  <StaticQuery
    query={MENU_QUERY}
    render={(data) => {
      return (
        <div className="nav__container-right">
          <nav role="navigation" aria-label="Main menu items">
            <RenderMenuItems data={data.wp.menuItems.nodes} />
          </nav>
          <Search collapse indices={searchIndices} />
        </div>
      );
    }}
  />
);

export default SiteMenu;

const MENU_QUERY = graphql`
  fragment MenuFields on WP_MenuItem {
    id
    label
    url
    connectedObject {
      __typename
    }
  }
  query GET_MENU_ITEMS_DESKTOP {
    wp {
      menuItems(where: { location: MAIN_NAV }) {
        nodes {
          ...MenuFields
          childItems {
            nodes {
              ...MenuFields
              childItems {
                nodes {
                  ...MenuFields
                  childItems {
                    nodes {
                      ...MenuFields
                      childItems {
                        nodes {
                          ...MenuFields
                          childItems {
                            nodes {
                              ...MenuFields
                              childItems {
                                nodes {
                                  ...MenuFields
                                  childItems {
                                    nodes {
                                      ...MenuFields
                                      childItems {
                                        nodes {
                                          ...MenuFields
                                          childItems {
                                            nodes {
                                              ...MenuFields
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
