import React from "react";
import Media from "react-media";
import FirmLogoDesktop from "../../images/djbooking-logo-desktop.svg";
import FirmLogoMobile from "../../images/xe-logo.png";

export default function Logo() {
  return (
    <React.Fragment>
      <Media query={{ minWidth: 640 }}>
        <img
          className="nav__logo"
          src={FirmLogoDesktop}
          alt="Xcluzive Entertainment logo"
          width="160px"
        />
      </Media>
      <Media query={{ maxWidth: 640 }}>
        <img
          className="nav__logo"
          src={FirmLogoMobile}
          alt="Xcluzive Entertainment logo"
          width="40px"
        />
      </Media>
    </React.Fragment>
  );
}
