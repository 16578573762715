import React, { Component } from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import FooterTitle from "./FooterTitle";
import Logo from "../../images/xcluzive-entertainment-logo.svg";
import LinkInternal from "../Links/LinkInternal";
import LinkExternal from "../Links/LinkExternal";
import Container from "../Container";

const FooterWrapper = styled.footer`
  ${tw`bg-black-900 sm:-mx-0 sm:m-0 py-16 sm:py-32`}
`;

const Content = styled.div`
  ${tw`flex flex-wrap`}
`;

const LogoColumn = styled.div`
  ${tw`w-5/6 sm:w-5/6 md:w-1/2 lg:w-1/4 sm:px-4 pt-4 sm:pt-12 pb-4`}
`;

const Column = styled.div`
  ${tw`w-5/6 sm:w-5/6 md:w-1/2 lg:w-1/4 sm:p-4`}
`;

class Footer extends Component {
  render() {
    return (
      <FooterWrapper>
        <Container>
          <Content>
            <LogoColumn>
              <img src={Logo} alt="Xcluzive Entertainment Logo" width="60%" />
            </LogoColumn>
            <Column>
              <FooterTitle titleText="Kontakt os" />
              <p itemProp="name">Xcluzive Entertainment ApS</p>
              <p>Teglbakken 38</p>
              <p>8270 Højbjerg</p>
              <p>
                <span itemProp="telephone">
                  <LinkExternal
                    itemScope
                    itemType="http://schema.org/LocalBusiness"
                    href="tel:+4570217025"
                    text="+45 7021 7025"
                    rel="nofollow"
                  />
                </span>
              </p>
              <p>
                <LinkExternal
                  href="mailto:niels@djbooking.dk"
                  text="niels@djbooking.dk"
                  rel="nofollow"
                />
              </p>
            </Column>
            <Column>
              <FooterTitle titleText="Top Artister" />
              <p>
                <LinkInternal href="/artist/suspekt/" text="Suspekt" />
              </p>
              <p>
                <LinkInternal
                  href="/artist/mads-laumann/"
                  text="Mads Laumann"
                />
              </p>
              <p>
                <LinkInternal href="/artist/nik-og-jay/" text="Nik & Jay" />
              </p>
            </Column>
            <Column>
              <FooterTitle titleText="Hvorfor DJbooking" />
              <p>Vælg mellem landets bedste DJs og Live Musik</p>
              <p>+25 års erfaring og professionel rådgivning</p>
              <p>En skriftlig kontrakt på aftalen</p>
            </Column>
          </Content>
        </Container>
      </FooterWrapper>
    );
  }
}

export default Footer;
