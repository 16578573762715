import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { SearchIcon, Form, Input } from './styles'
import Media from 'react-media'

export default connectSearchBox(({ refine, ...rest }) => (
  <Form>
    <Input type="text" placerholder="Søg artist" aria-label="Search" onChange={e => refine(e.target.value)} {...rest} />
    <SearchIcon />
  </Form>
))
