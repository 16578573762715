import React from "react";
import { graphql } from "gatsby";
import Layout from "./Layout/index";
import MainMenu from "./Menu/MainMenu";
import HelmetSeo from "./Helmet/Helmet";
import Footer from "./Footer/Footer";

function HtmlWrapper({ seo, children }) {
  return (
    <React.Fragment>
      <Layout>
        <HelmetSeo {...seo} />
        <MainMenu />
        <main>{children}</main>
        <Footer />
      </Layout>
    </React.Fragment>
  );
}

export default HtmlWrapper;

export const query = graphql`
  fragment YoastPost on WP_Post {
    seo {
      ...YoastFields
    }
  }

  fragment YoastPage on WP_Page {
    seo {
      ...YoastFields
    }
  }

  fragment YoastAnmeldelse on WP_Review {
    seo {
      ...YoastFields
    }
  }

  fragment YoastArtist on WP_Artist {
    seo {
      ...YoastFields
    }
  }
`;
